import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        this.element.addEventListener('click', (e) => {
            e.preventDefault()
            document.body.classList.toggle('show-promotionalproducts-filter');
            return false
        })
    }
}
